import { render, staticRenderFns } from "./CampaignLandingPage.vue?vue&type=template&id=71e8c841&scoped=true"
import script from "./CampaignLandingPage.vue?vue&type=script&lang=ts"
export * from "./CampaignLandingPage.vue?vue&type=script&lang=ts"
import style0 from "./CampaignLandingPage.vue?vue&type=style&index=0&id=71e8c841&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e8c841",
  null
  
)

export default component.exports